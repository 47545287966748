import axios from 'axios';

const API_URL = 'https://is.gradient.ru/anticovidtest'

const ping = 'https://is.gradient.ru/anticovidtest/ping'

export const gradientAPI = {
  getPing () {
    return axios.get(ping)
  },
  getAuthUser (value) {
    console.log('API: ', value)
    const sendingData = JSON.stringify({
      Login: value.dataForm.login.trim(),
      Password: value.dataForm.password.trim()
    })

    console.log(sendingData)
    
    return axios({
      method: "post",
      url: `${API_URL}/auth`,
      data: sendingData,
      headers: { "Content-Type": "application/json" }, 
    })
  },
  getUpdate (value) {
    console.log(value)
    const sendingData = JSON.stringify({
      Login: value.values.Login,
      UserRole: value.values.User,
      LName: value.values.LName,
      FName: value.values.FName,
      MName: value.values.MName,
      DateOfBirthDate: value.values.DateOfBirthDate,
      Vaccinations: {
        Vaccination: [...value.values.Vaccinations.Vaccination],
        PageInfo: value.values.Vaccinations.PageInfo
      },
      Tests: {
        Test: [...value.values.Tests.Test],
        PageInfo: value.values.Tests.PageInfo
      },
    })
    console.log(sendingData)
    return axios({
      method: "post",
      url: `${API_URL}/update`,
      data: sendingData,
      headers: { "Content-Type": "application/json" }, 
    })
  },
  getScan (value) {
    console.log(value)
    const sendingData = JSON.stringify({
      Login: value.values.Login,
      id: value.values.id,
      TypeScan: value.values.TypeScan
    })
    console.log(sendingData)
    return axios({
      method: "post",
      url: `${API_URL}/GetScanContent`,
      data: sendingData,
      headers: { "Content-Type": "application/json" }, 
    })
  },
  getTests (value) {
    console.log(value)
    const sendingData = JSON.stringify({
      PageInfo: {
        PageNumber: value.values,
        PageSize: 20
      }
    })
    return axios({
      method: "post",
      url: `${API_URL}/getTests`,
      data: sendingData,
      headers: { "Content-Type": "application/json" }, 
    })
  },
  getVacc (value) {
    console.log(value)
    const sendingData = JSON.stringify({
      PageInfo: {
        PageNumber: value.values,
        PageSize: 20
      }
    })
    return axios({
      method: "post",
      url: `${API_URL}/getVaccinations`,
      data: sendingData,
      headers: { "Content-Type": "application/json" }, 
    })
  },
  del (value) {
    console.log(value)
    const sendingData = JSON.stringify({
      login: value.values.Login,
      id: value.values.id,
      TypeScan: value.values.TypeScan
    })
    console.log(sendingData)
    return axios({
      method: "post",
      url: `${API_URL}/deleteScan`,
      data: sendingData,
      headers: { "Content-Type": "application/json" }, 
    })
  },
  downloadListOfVaccinations () {
    console.log('Download list of vaccination')
    return axios({
      method: "post",
      url: `${API_URL}/getReportVaccination`,
      data: {},
      // headers: { "Content-Type": "application/json" }, 
      responseType: 'blob'
    })
  },
  downloadListOfPCRTest () {
    console.log('Download list of pcrTest')
    return axios({
      method: "post",
      url: `${API_URL}/getReportTest`,
      data: {},
      // headers: { "Content-Type": "application/json" }, 
      responseType: 'blob'
    })
  },
}
