import React, { useState, useEffect } from "react"
import { ScanModal } from "../../pages/ScanModal/ScanModal"
import Loader2 from "../../components/Loader2/Loader2"
import "./TestsComponent.scss"
import _ from 'lodash'
import { columnsAdminTest } from '../../static/forSortAdminTable'
import { TableHeader } from "../TableHeader/TableHeader"
import { TableBody } from "../TableBody/TableBody"
import { PaginationsAdmin } from "../PaginationsAdmin/PaginationAdmin"

export const TestsComponent = ({
  handlerTests,
  AdminTest,
  dispatch,
  useSelector,
  sagalistPCRWotcher,
  setIsDataSending,
  selectedSort,
  setSortBy
}) => {

  useEffect(() => {
    return () => dispatch(setIsDataSending(false));
  }, [])

  //1)for sort data with lodash:
  const sortTestArr = _.orderBy(AdminTest[0].Test, [selectedSort.path], [selectedSort.order])

  const [isModalScan, setIsModal] = useState(false);
  const [imgSrc, setImgSrc] = useState("");
  const [idScan, setIdScan] = useState("");
  const [login, setLogin] = useState(null)

  const isDataSending = useSelector((state) => state.userReducer.isDataSending);

  const showModalScan = (imgValue, id, login) => {
    setIsModal(true);
    setImgSrc(imgValue);
    setIdScan(id);
    setLogin(login);
  };
  const getListPCRTest = () => {
    dispatch(setIsDataSending(true));
    dispatch(sagalistPCRWotcher());
  };

  return (
    <>
      {isDataSending && (
        <div className="loader-block">
          <Loader2 />
        </div>
      )}
      {isModalScan && (
        <ScanModal
          setIsModal={setIsModal}
          imgSrc={imgSrc}
          idScan={idScan}
          login={login}
        />
      )}
      {AdminTest !== null && (
        <>
          <button onClick={getListPCRTest} className="btn btn-import-excel">
            Экспорт в Excel
          </button>
          <table className="tests-table">
            <TableHeader 
              columns={columnsAdminTest}
              selectedSort={selectedSort}
              setSortBy={setSortBy}
            />
            <TableBody 
              data={sortTestArr}
              showModalScan={showModalScan}
              columns={columnsAdminTest}
              typeImg={'getPcr'}
            />
          </table>
          <PaginationsAdmin 
            data={AdminTest[0]}
            handlerPages={handlerTests}
          />
        </>
      )}
    </>
  );
};
