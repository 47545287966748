import React, { useState } from 'react'
import Calendar from 'react-calendar'
import calendarPNG from  '../../assets/today_black_24dp.svg'
import checkedPNG from '../../assets/checked1.png'
// import savePNG from '../../assets/icons8-save-as-50.png'
import savePNG from '../../assets/save1X.png'
// import deletePNG from '../../assets/delete1x.png'
import { ScanModal } from '../../pages/ScanModal/ScanModal'
import closePNG from '../../assets/close1x.png'
// import Loader from '../../components/Loader/Loader'
import { setIsDataSending } from '../../redux/AC'

export const PCRTest2 = (props) => {

  const { 
    handleDate, 
    currentSelectedData,
    getBase64,
    Login,
    dispatch,
    updateSaga,
    setShowPCRTest2,
    Tests
  } = props

  //  console.log(isDataSending)

  const [showcalendar3, setShowcalendar3] = useState(false)

  const [selectedPcrTestDay, setSelectedPcrTestDay] = useState('')

  const [filePcr, setFileSPcr] = useState(null);
  const [isModalScan, setIsModal] = useState(false)
  const [imgSrc, setImgSrc] = useState('')
  const [idScan, setIdScan] = useState('')

  const showModalScan = (imgValue, id) => {
    console.log('showModalScan!! local')
    setIsModal(true)
    setImgSrc(imgValue)
    setIdScan(id)
  }

  const updateDataUser = (id) => {
    let datePcrTestDay
    dispatch(setIsDataSending(true))
    if (selectedPcrTestDay !== '') datePcrTestDay = handleDate(selectedPcrTestDay)
    const values = {
      Login,
      Tests: {
        Test: [
          {
            id,
            DateTest: datePcrTestDay,
            ScanTest: filePcr,
          }
        ]
      },
      Vaccinations: {
        Vaccination: []
      }
    }
    dispatch(updateSaga(values))
    setShowPCRTest2(false)
  }

  return (
    <>
      {/* { 
        isDataSending &&
        <div className="loader-container">
          <Loader />
        </div>
      } */}
      {
        isModalScan &&
        <ScanModal 
          setIsModal={setIsModal}
          imgSrc={imgSrc}
          idScan={idScan}
        />
      }
      <tbody>
        <tr>
          <td></td>
          <td>
            <div className="react-calendar">
              <img src={calendarPNG} alt="" onClick={() => setShowcalendar3(!showcalendar3)} />
                <div className="show-date">
                  {handleDate(selectedPcrTestDay)}
                </div>
              { showcalendar3 &&
                <Calendar
                  onClickDay={(e) => currentSelectedData(e, setSelectedPcrTestDay, setShowcalendar3)}
                />
              }
            </div>
          </td>
          <td className={filePcr ? 'attached' : ''}>
            <label className="custom-file-upload">
              <input type="file" accept="application/pdf,image/jpeg,image/png" onChange={(e) => getBase64(e, setFileSPcr)} />
              Прикрепить ПЦР-скан
            </label>
            {
              filePcr &&
              <img src={checkedPNG} alt="" onClick={() => showModalScan(filePcr)}/>
            }
          </td>
          <td className="save-td">
            <img src={savePNG} alt="" onClick={() => updateDataUser()}/>
          </td>
          {
            Tests.Test.length > 0 &&
            <td className="delete-td" onClick={() => setShowPCRTest2(false)}>
              <img src={closePNG} alt="" />
            </td>
          }
        </tr>
      </tbody>
    </>
  )
}