import React, { useState, useEffect } from 'react'
import './AdminPage.scss'
import { useDispatch, useSelector } from 'react-redux'
import { 
  sagaTests, 
  sagaVacc, 
  resetAdminTest, 
  resetAdminVacc, 
  sagalistVaccWotcher, 
  setIsDataSending, 
  sagalistPCRWotcher } from '../../redux/AC'
import { AdminHeader } from '../AdminHeader/AdminHeader'
import { TestsComponent } from '../../components/TestsComponent/TestsComponent'
import { VaccComponent } from '../../components/VaccComponents/VaccComponents'
import Loader from '../../components/Loader/Loader'

export const AdminPage = () => {

  const dispatch = useDispatch()
  const AdminTest = useSelector((state) => state.userReducer.AdminTest)
  const AdminVacc = useSelector((state) => state.userReducer.AdminVacc)

  const showVaccination = useSelector((state) => state.userReducer.showVaccination)
  const showTests = useSelector((state) => state.userReducer.showTests)

  //1) for sort data with lodash:
  const [sortBy, setSortBy] = useState({path: 'Created', order: "asc"})

  useEffect(() => {
    dispatch(sagaTests(1))
  }, [])

  const handlerTests = (number) => {
    dispatch(resetAdminVacc())
    dispatch(sagaTests(number))
  }

  const handlerVacc = (pageValue) => {
    console.log('handlerVacc')
    dispatch(resetAdminTest())
    dispatch(sagaVacc(pageValue))
  }

  return (
    <div className="admin-page-tests">
      <AdminHeader
        AdminTest={AdminTest}
        handlerTests={handlerTests}
        AdminVacc={AdminVacc}
        handlerVacc={handlerVacc}
      />
      {(AdminTest === null && showTests)  &&
        <div className="loader-container"><Loader /></div> 
      }
      {AdminTest !== null &&
        <TestsComponent
          handlerTests={handlerTests}
          AdminTest={AdminTest}
          dispatch={dispatch}
          useSelector={useSelector}
          sagalistPCRWotcher={sagalistPCRWotcher}
          setIsDataSending={setIsDataSending}
          selectedSort={sortBy}
          setSortBy={setSortBy}
        />
      }
      {(AdminVacc === null && showVaccination) &&
        <div className="loader-container"><Loader /></div> 
      }
      {AdminVacc !== null && 
        <VaccComponent 
          handlerVacc={handlerVacc}
          AdminVacc={AdminVacc}
          dispatch={dispatch}
          useSelector={useSelector}
          sagalistVaccWotcher={sagalistVaccWotcher}
          setIsDataSending={setIsDataSending}
          selectedSort={sortBy}
          setSortBy={setSortBy}
        />
      }
    </div>
  )
}