import { put, takeEvery, call } from 'redux-saga/effects'
import { gradientAPI } from '../../api/api'
import { setIsDataSending, updateSaga } from '../AC'
import { userConsts } from '../../static/consts'

function* deleteWorker (values) {
  console.log('??????: ', values)
  try {
    const response = yield call(gradientAPI.del, values)
    if (response.data.IsError) {
      console.log('IsError: ', response.data.IsError)
    } else {
      yield put (setIsDataSending(false))
    }
  } catch (err) {
    console.log(err)
  }
}

export function* deleteWotcher () {
  yield takeEvery(userConsts.SAGA_DEL, deleteWorker) 
}