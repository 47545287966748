import React, { useState, useEffect } from "react";
import { ScanModal } from "../../pages/ScanModal/ScanModal";
import Loader2 from "../../components/Loader2/Loader2"
import { columnsAdminVacc } from '../../static/forSortAdminTable'
import "./VaccComponent.scss"
import _ from 'lodash'
import { TableHeader } from "../TableHeader/TableHeader"
import { TableBody } from "../TableBody/TableBody"
import { PaginationsAdmin } from "../PaginationsAdmin/PaginationAdmin";

export const VaccComponent = ({
  handlerVacc,
  AdminVacc,
  dispatch,
  useSelector,
  sagalistVaccWotcher,
  setIsDataSending,
  selectedSort,
  setSortBy
}) => {

  useEffect(() => {
    return dispatch(setIsDataSending(false));
  }, []);

  //1)for sort data with lodash:
  const sortVaccArr = _.orderBy(AdminVacc[0].Vaccination, [selectedSort.path], [selectedSort.order])

  const [isModalScan, setIsModal] = useState(false);
  const [imgSrc, setImgSrc] = useState("");
  const [idScan, setIdScan] = useState("");
  const [login, setLogin] = useState(null);

  const isDataSending = useSelector((state) => state.userReducer.isDataSending);

  const showModalScan = (imgValue, id, login) => {
    setIsModal(true);
    setImgSrc(imgValue);
    setIdScan(id);
    setLogin(login);
  };

  const getListVacc = () => {
    console.log("Lets start to download");
    dispatch(setIsDataSending(true));
    dispatch(sagalistVaccWotcher());
  };

  return (
    <>
      {isDataSending && (
        <div className="loader-block">
          <Loader2 />
        </div>
      )}
      {isModalScan && (
        <ScanModal
          setIsModal={setIsModal}
          imgSrc={imgSrc}
          idScan={idScan}
          login={login}
        />
      )}
      {AdminVacc !== null && (
        <>
          <button onClick={getListVacc} className="btn btn-import-excel">
            Экспорт в Excel
          </button>
          <table className="vacc-table">
            <TableHeader 
              columns={columnsAdminVacc}
              selectedSort={selectedSort}
              setSortBy={setSortBy}
            />
            <TableBody 
              data={sortVaccArr}
              showModalScan={showModalScan}
              columns={columnsAdminVacc}
              typeImg={'getCertV'}
            />
          </table>
          <PaginationsAdmin 
            data={ AdminVacc[0]}
            handlerPages={handlerVacc}
          />
        </>
      )}
    </>
  );
};
