import React from 'react'


export const PaginationsAdmin = ({ data, handlerPages }) => {
  
  const pages = []
  for (let i = 1; i <= data.PageInfo.TotalPages; i++) {
    pages.push(i)
  }

  return (
    <div className='paginations'>
      {pages.length > 1 &&
        pages.map(page => ((
          <span 
            key={page} 
            className={page === data.PageInfo.PageNumber ? 'active-page' : ''}
            onClick={() => handlerPages(page)}
          >
            {page}
          </span>)))
      }
    </div>
  )
  
}