export const columnsUserTest = {
  Created: { path: 'Created', name: 'Дата время записи' },
  DateTest: { path: 'DateTest', name: 'Дата проведения теста на ПЦР' },
  HaveScan: { path: 'HaveScan', name: 'Скан теста на ПЦР' },
  edit: { path: '', name: ''},
  delete: { path: '', name: ''}
}
export const columnsUserVacc = {
  Created: { path: 'Created', name: 'Дата время записи' },
  DateOfFirstVaccination: { path: 'DateOfFirstVaccination', name: '1ая вакцинация' },
  DateOfSecondVaccination: { path: 'DateOfSecondVaccination', name: '2ая вакцинация' },  
  HaveScan: { path: 'HaveScan', name: 'Скан сертификата прививки' },
  edit: { path: '', name: ''},
  delete: { path: '', name: ''}
}

//Created: "26.07.2021 00:30:05"
//DateTest: "01.07.2021"
//HaveScan: true
