import React from 'react'
import arrowDownPNG from '../../assets/arrow_down_white_1x.png'
import arrowUpPNG from '../../assets/arrow_up_white_1x.png'

export const TableHeader = ({ columns, selectedSort, setSortBy }) => {
 
  //1) for sort data with lodash:
    const handleSort = (path) => {
      if (selectedSort.path === path) {
        setSortBy({
          ...selectedSort,
          order: selectedSort.order === 'asc' ? 'desc' : 'asc'
        })
      } else {
        setSortBy({path: path, order: 'asc'})
      }
    }
  
    return (
      <thead>
        <tr>
          {Object.keys(columns).map(column => (
            <th 
              key={column} 
              onClick={columns[column].path ? () => handleSort(columns[column].path) : undefined} 
            >
              {columns[column].name}
              {(columns[column].path === selectedSort.path && selectedSort.order === 'desc') &&
                <div><img src={arrowUpPNG} alt=""/></div>
              }
              {(columns[column].path === selectedSort.path && selectedSort.order === 'asc') &&
                <div><img src={arrowDownPNG} alt="" /></div>
              }
            </th>
          ))}
        </tr>
      </thead>
    )
}