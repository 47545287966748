import React, { useState } from 'react'
import './Vaccination.scss'
import { useDispatch, useSelector } from 'react-redux'
import { updateSaga } from '../../redux/AC'
import { Vaccination2 } from '../Vaccination2/Vaccination2'
import plusPNG from '../../assets/plus2x.png'
import { handleDate, getBase64, currentSelectedData } from '../../static/functions'
import { TRModalVacc } from '../../pages/TRModalVacc/TRModalVacc'
import Loader from '../../components/Loader/Loader'
import _ from 'lodash'
import { columnsUserVacc } from '../../static/forSortUserTable'
import { TableHeader } from '../TableHeader/TableHeader'
import { TableBody } from '../TableBody/TableBody'
import { Paginations } from '../Paginations/Pagination'

export const Vaccination = ({ selectedSort, setSortBy, showModalScan, updateDataUser, deleteValue, Vaccinations }) => {

  const [isEditTR, setIsEdidTR] = useState(false)
  const [dataEdit, setDataEdit] = useState(null)

  const dispatch = useDispatch()
  const [showVaccination2, setShowVaccination2] = useState(false)

  const Login = useSelector((state) => state.userReducer.Login) 
  // const Vaccinations = useSelector((state) => state.userReducer.Vaccinations)
  const isDataSending = useSelector((state) => state.userReducer.isDataSending)

  //1)for sort data with lodash:
  const sortVaccArr = _.orderBy(Vaccinations.Vaccination, [selectedSort.path], [selectedSort.order])

  const editTRData = (vacc) => {
    setIsEdidTR(true)
    setDataEdit(vacc)
  }

  return (
    <>
      {isDataSending &&
        <div className="loader-container">
          <Loader />
        </div>
      }
      {isEditTR &&
        <TRModalVacc
          dataEdit={dataEdit}
          setIsEdidTR={setIsEdidTR}
        />
      }
      <h2 className="title">ВАКЦИНАЦИЯ</h2>
      <table className="table-vcc">
        <TableHeader 
          columns={columnsUserVacc}
          selectedSort={selectedSort}
          setSortBy={setSortBy}
        />
        {Vaccinations.Vaccination.length > 0 ? (
          <TableBody 
            data={sortVaccArr}
            showModalScan={showModalScan}
            columns={columnsUserVacc}
            editTRData={editTRData}
            deleteValue={deleteValue}
            typeImg={'getCertV'}
            typeScan={'ScanOfVaccinationCertificate'}
          />
        ) : (
          <Vaccination2
            handleDate={handleDate}
            currentSelectedData={currentSelectedData}
            getBase64={getBase64}
            Login={Login}
            dispatch={dispatch}
            updateSaga={updateSaga}
            setShowVaccination2={setShowVaccination2}
            Vaccinations={Vaccinations}
          />
        )}
        {showVaccination2 &&
          <Vaccination2
            handleDate={handleDate}
            currentSelectedData={currentSelectedData}
            getBase64={getBase64}
            Login={Login}
            dispatch={dispatch}
            updateSaga={updateSaga}
            setShowVaccination2={setShowVaccination2}
            Vaccinations={Vaccinations}
          /> 
        }
    </table>
    {Vaccinations.Vaccination.length > 0 &&
      <Paginations
        data={Vaccinations}
        updateDataUser={updateDataUser} 
        typeScan={'ScanOfVaccinationCertificate'}
      />
    }
    {(Vaccinations.Vaccination.length > 0 && !showVaccination2) &&
      <div className="plus-actions" onClick={() => setShowVaccination2(!showVaccination2)}>
        <img src={plusPNG} alt="" />
      </div>
    }
  </>
  )
}