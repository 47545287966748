import React from 'react'
import checkedPNG from "../../assets/checked1.png"
import pencilPNG from '../../assets/pencilBlack1x.png'
import deletePNG from '../../assets/delete1x.png'

export const TableBody = ({ data, showModalScan, typeImg, typeScan, columns, ...rest }) => {

  return (
    <tbody>
      {data.map(item => {
        return (
          <tr key={item.id}>
            {Object.keys(columns).map(column => (
              column === 'HaveScan' ? (
                <td className={column === 'HaveScan' && item[column] ? "attached" : ""} key={column}>
                  {column === 'HaveScan' && item[column] ? (
                    <img
                      src={checkedPNG}
                      alt=""
                      onClick={() =>
                        showModalScan(typeImg, item.id, item.Login)
                      }
                    />
                  ) : ("Скан отсутствует")
                  }
                </td> 
              ) : column === 'edit' ? (
                <td className="edit-td" key={column}>
                  <img src={pencilPNG} alt="" onClick={() => rest.editTRData(item)}/>
                </td> 
              ) : column === 'delete' ? (
                <td className="delete-td" key={column}>
                  <img src={deletePNG} alt="" onClick={() => rest.deleteValue(item.id, typeScan)}/>
                </td>
              ) : (
                <td key={column}>{item[column]}</td>
              )
            ))}
          </tr>)
        })}
    </tbody>
  )
}