export const columnsAdminTest = {
  Created: { path: 'Created', name: 'Дата время записи' },
  LName: { path: 'LName', name: 'Фамилия' },
  FName: { path: 'FName', name: 'Имя' },
  MName: { path: 'MName', name: 'Отчество' },
  DateOfBirthDate: { path: 'DateOfBirthDate', name: 'Дата рождения' },
  DateTest: { path: 'DateTest', name: 'Дата теста на ПЦР' },
  HaveScan: { path: 'HaveScan', name: 'Скан теста на ПЦР' }
}

export const columnsAdminVacc = {
  Created: { path: 'Created', name: 'Дата время записи' },
  LName: { path: 'LName', name: 'Фамилия' },
  FName: { path: 'FName', name: 'Имя' },
  MName: { path: 'MName', name: 'Отчество' },
  DateOfBirthDate: { path: 'DateOfBirthDate', name: 'Дата рождения' },
  DateOfFirstVaccination: { path: 'DateOfFirstVaccination', name: 'Дата 1й вакцинации' },
  DateOfSecondVaccination: { path: 'DateOfSecondVaccination', name: 'Дата 2й вакцинации' },
  HaveScan: { path: 'HaveScan', name: 'Скан теста на ПЦР' }
}

// Created: "26.07.2021 14:02:02"
// DateOfBirthDate: null
// DateTest: "01.07.2021"
// FName: "Константин"
// HaveScan: true
// LName: "Залилов"
// Login: "k.zalilov"
// MName: "Юрьевич"

// Created: "23.07.2021 16:11:17"
// DateOfBirthDate: "05.03.1988"
// DateOfFirstVaccination: "05.07.2021"
// DateOfSecondVaccination: "26.07.2021"
// FName: "Дмитрий"
// HaveScan: false
// LName: "Астахов"
// Login: "d.astahov"
// MName: "Алексеевич"

