import React, { useState } from 'react'
import Calendar from 'react-calendar'
import { ScanModal } from '../../pages/ScanModal/ScanModal'
import calendarPNG from  '../../assets/today_black_24dp.svg'
import checkedPNG from '../../assets/checked1.png'
// import savePNG from '../../assets/icons8-save-as-50.png'
import savePNG from '../../assets/save1X.png'
import closePNG from '../../assets/close1x.png'
// import deletePNG from '../../assets/delete1x.png'
import { setIsDataSending } from '../../redux/AC'

export const Vaccination2 = (props) => {
  
  const { 
    handleDate, 
    currentSelectedData,
    getBase64,
    Login,
    dispatch,
    updateSaga,
    setShowVaccination2,
    Vaccinations
   } = props
  
   
  const [showcalendar1, setShowcalendar1] = useState(false)
  const [showcalendar2, setShowcalendar2] = useState(false)
  const [fileSert, setFileSert] = useState(null);
  const [selectedVaccineDay1, setSelectedTmpVaccineDay1] = useState('')
  const [selectedVaccineDay2, setSelectedTmpVaccineDay2] = useState('')
  const [isModalScan, setIsModal] = useState(false)
  const [imgSrc, setImgSrc] = useState('')
  const [idScan, setIdScan] = useState('')

  const updateDataUser = (id) => {
    dispatch(setIsDataSending(true))
    console.log('!!!!', id)
    let 
      dateVaccine1,
      dateVaccine2

    if (selectedVaccineDay1 !== '') dateVaccine1 = handleDate(selectedVaccineDay1)
    if (selectedVaccineDay2 !== '') dateVaccine2 = handleDate(selectedVaccineDay2)

    const values = {
      Login,
      Vaccinations: {
        Vaccination: [
          {
            id,
            DateOfFirstVaccination: dateVaccine1,
            DateOfSecondVaccination: dateVaccine2,
            ScanOfVaccinationCertificate: fileSert,
          }
        ]
      },
      Tests: {
        Test: []
      }
    }
    console.log(values)
    dispatch(updateSaga(values))
    setShowVaccination2(false)
  }

  const showModalScan = (imgValue, id) => {
    setIsModal(true)
    setImgSrc(imgValue)
    setIdScan(id)
  }
  return (
    <>
      {
        isModalScan &&
        <ScanModal 
          setIsModal={setIsModal}
          imgSrc={imgSrc}
          idScan={idScan}
        />
      }
      <tbody>
        <tr>
          <td></td>
          <td>
            <div className="react-calendar">
              <img src={calendarPNG} alt="" onClick={() => setShowcalendar1(!showcalendar1)} />
                <div className="show-date">
                  {handleDate(selectedVaccineDay1)}
                </div>
              { showcalendar1 &&
                <Calendar
                  onClickDay={(e) => currentSelectedData(e, setSelectedTmpVaccineDay1, setShowcalendar1)}
                />
              }
            </div>
          </td>
          <td>
            <div className="react-calendar">
              <img src={calendarPNG} alt="" onClick={() => setShowcalendar2(!showcalendar2)} />
                <div className="show-date">
                  {handleDate(selectedVaccineDay2)}
                </div>
              { showcalendar2 &&
                <Calendar
                  onClickDay={(e) => currentSelectedData(e, setSelectedTmpVaccineDay2, setShowcalendar2)}
                />
              }
            </div>
          </td>
          <td className={fileSert ? 'attached' : ''}>
            <label className="custom-file-upload">
              <input type="file" accept="application/pdf,image/jpeg,image/png" onChange={(e) => getBase64(e, setFileSert)} />
              Прикрепить сертификат
            </label>
            {
              fileSert &&
              <img src={checkedPNG} alt="" onClick={() => showModalScan(fileSert)}/>
            }
          </td>
          <td className="save-td">
              <img src={savePNG} alt="" onClick={() => updateDataUser()}/>
          </td>
          {
            Vaccinations.Vaccination.length > 0 &&
            <td className="delete-td" onClick={() => setShowVaccination2(false)}>
              <img src={closePNG} alt="" />
            </td>
          }
        </tr>
      </tbody>
    </> 
  )
}