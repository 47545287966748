import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { updateSaga, setIsDataSending } from '../../redux/AC'


export const Paginations = ({ data, typeScan }) => {
  const dispatch = useDispatch()

  const Login = useSelector((state) => state.userReducer.Login)
  // const Vaccinations = useSelector((state) => state.userReducer.Vaccinations)
  // const Tests = useSelector((state) => state.userReducer.Tests)

  const updateDataUser = (numberPage = 1, typeScan) => {
    dispatch(setIsDataSending(true))
    let values = null
    if (typeScan === 'ScanOfVaccinationCertificate') {
      values = {
        Login,
        Vaccinations: {
          Vaccination: [],
          PageInfo: {
            PageNumber: numberPage,
          }
        },
        Tests: {
          Test: []
        }
      }
    } else {
      values = {
        Login,
        Tests: {
          Test: [],
          PageInfo: {
            PageNumber: numberPage,
          }
        },
        Vaccinations: {
          Vaccination: []
        }
      }
    }
    dispatch(updateSaga(values))
  }
  
  const pages = []
  for (let i = 1; i <= data.PageInfo.TotalPages; i++) {
    pages.push(i)
  }

  return (
    <div className='paginations'>
      {
        pages.length > 1 &&
        pages.map(page => ((
          <span 
            key={page} 
            className={page === data.PageInfo.PageNumber ? 'active-page' : ''}
            onClick={() => updateDataUser(page, typeScan)}
          >
            {page}
          </span>)))
      }
    </div>
  )
}