import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from "react-router-dom"
import { updateSaga, sagaDel, setIsDataSending } from '../../redux/AC'
import { saveInLocalStorage } from '../../static/functions'
//components:
import { ScanModal } from '../ScanModal/ScanModal'
import { InputModal } from '../InputModal/InputModal'
import { BirthDayModal } from '../BirthDayModal/BirthDayModal'
import { HeaderComponent } from '../../components/HeaderComponent/HeaderComponent'
import { Vaccination } from '../../components/Vaccination/Vaccination'
import { PCRTest } from '../../components/PCRTest/PCRTest'
//css&images:
import './UserPage.scss'
import 'react-calendar/dist/Calendar.css';
import pencilPNG from '../../assets/pencilBlack1x.png'
import navigatePNG from '../../assets/navigate1x.png'
import { Footer } from '../Footer/Footer'

export const UserPage = () => {

  const dispatch = useDispatch()
  const history = useHistory()

  const [isModalInput, setIsModalInput] = useState(false)
  const [isModalBD, setIsModalBD] = useState(false)

  const [isModalScan, setIsModal] = useState(false)
  const [imgSrc, setImgSrc] = useState('')
  const [idScan, setIdScan] = useState('')

  const [numberPage, setNumberPage] = useState(1)
  
  //1) for sort data with lodash:
  const [sortBy, setSortBy] = useState({path: 'Created', order: "asc"})

  const Login = useSelector((state) => state.userReducer.Login)
  const FName = useSelector((state) => state.userReducer.FName)
  const LName = useSelector((state) => state.userReducer.LName)
  const MName = useSelector((state) => state.userReducer.MName)
  const DateOfBirthDate = useSelector((state) => state.userReducer.DateOfBirthDate)
  const UserRole = useSelector((state) => state.userReducer.UserRole)

  const Vaccinations = useSelector((state) => state.userReducer.Vaccinations)
  const Tests = useSelector((state) => state.userReducer.Tests)

  console.log('And tests are: ', Tests)

  const showVaccination = useSelector((state) => state.userReducer.showVaccination)
  const showTests = useSelector((state) => state.userReducer.showTests)

  useEffect(() => {
    saveInLocalStorage({
      Login, FName, LName, MName, DateOfBirthDate, Vaccinations, Tests, UserRole
    })
  }, [])

  useEffect(() => {
    saveInLocalStorage({
      Login, FName, LName, MName, DateOfBirthDate, Vaccinations, Tests, UserRole })
  }, [Login])

  if (!Login) {
    dispatch(updateSaga(JSON.parse(localStorage.getItem('dataUser'))))
  }

  const showModalScan = (imgValue, id) => {
    setIsModal(true)
    setImgSrc(imgValue)
    setIdScan(id)
  }
  
  const updateDataUser = (typeScan) => {
    let values = null
    if (typeScan === 'ScanOfVaccinationCertificate') {
      values = {
        Login,
        Vaccinations: {
          Vaccination: [],
          PageInfo: {
            PageNumber: Vaccinations.Vaccination.length === 1 ? Vaccinations.PageInfo.PageNumber - 1 : Vaccinations.PageInfo.PageNumber,
          }
        },
        Tests: {
          Test: []
        }
      }
    } else {
      values = {
        Login,
        Tests: {
          Test: [],
          PageInfo: {
            PageNumber: Tests.Test.length === 1 ? Tests.PageInfo.PageNumber - 1 : Tests.PageInfo.PageNumber,
          }
        },
        Vaccinations: {
          Vaccination: []
        }
      }
    }
    dispatch(updateSaga(values))
  }

  const deleteValue = (id, typeScan) => {
    console.log('Type IS: ', typeScan)
    dispatch(setIsDataSending(true))
    dispatch(sagaDel({
      id,
      Login,
      TypeScan: typeScan,
    }))
    updateDataUser(typeScan)
  }

  return (
    <div className="content">
    <HeaderComponent />
    <div className="userpage">
      {isModalScan &&
        <ScanModal 
          setIsModal={setIsModal}
          imgSrc={imgSrc}
          idScan={idScan}
        />
      }
      {isModalInput &&
        <InputModal 
          setIsModalInput={setIsModalInput}
          fname={FName}
          lname={LName}
          mname={MName === null ? '' : MName}
          login={Login}
        />
      }
      {isModalBD &&
        <BirthDayModal
          login={Login}
          bd={DateOfBirthDate}
          setIsModalBD={setIsModalBD}
        />
      }
      <div className="global-user">
        <div className="fio" onClick={() => setIsModalInput(true)}>
            <span>{LName} {FName} {MName}</span> <img src={pencilPNG} alt="" />
          </div>
          <div className="birthday" onClick={() => setIsModalBD(true)}>
            {DateOfBirthDate === null ? (
                <><span>Год рождения?</span> <img src={pencilPNG} alt="" /> </>
              ) : (
                <><span>{DateOfBirthDate}</span> <img src={pencilPNG} alt="" /></>
              )
            }
          </div>
      </div>
      { (UserRole === 'Moderator' || UserRole === 'Admin' || UserRole === 'SuperAdmin') &&
        <div className="go-to-full-list" onClick={() => {history.push('./adminpage')}}>
          <span>Расширенный список</span>
          <img src={navigatePNG} alt="" />
        </div>
      }
      {showVaccination &&
        <Vaccination
          Vaccinations={Vaccinations}
          selectedSort={sortBy}
          setSortBy={setSortBy}
          showModalScan={showModalScan}
          updateDataUser={updateDataUser}
          deleteValue={deleteValue}
        />
      }
      {showTests &&
        <PCRTest
          Tests={Tests}
          selectedSort={sortBy}
          setSortBy={setSortBy}
          showModalScan={showModalScan}
          updateDataUser={updateDataUser}
          deleteValue={deleteValue}
        />
      }
    </div>
    <Footer />
    </div> 
  )
}