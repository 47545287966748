import React, { useState } from 'react'
import './PCRTest.scss'
import { useDispatch, useSelector } from 'react-redux'
import plusPNG from '../../assets/plus2x.png'
import { handleDate, getBase64, currentSelectedData } from '../../static/functions'
import { updateSaga } from '../../redux/AC'
import { PCRTest2 } from '../PCRTest2/PCRTest2'
import { TRModalTest } from '../../pages/TRModalTest/TRModalTest'
import Loader from '../../components/Loader/Loader'
import _ from 'lodash'
import { columnsUserTest } from '../../static/forSortUserTable'
import { TableHeader } from '../TableHeader/TableHeader'
import { TableBody } from '../TableBody/TableBody'
import { Paginations } from '../Paginations/Pagination'

export const PCRTest = ({ selectedSort, setSortBy, showModalScan, updateDataUser, deleteValue, Tests }) => {

  console.log('!!!!!!!')
  const [isEditTR, setIsEdidTR] = useState(false)
  const [dataEdit, setDataEdit] = useState(null)
  const [showPCRTest2, setShowPCRTest2] = useState(false)

  const dispatch = useDispatch()

  const Login = useSelector((state) => state.userReducer.Login)
  // const Tests = useSelector((state) => state.userReducer.Tests)
  const isDataSending = useSelector((state) => state.userReducer.isDataSending)


  //1)for sort data with lodash:
   const sortTestArr = _.orderBy(Tests.Test, [selectedSort.path], [selectedSort.order])

  const editTRData = (vacc) => {
    setIsEdidTR(true)
    setDataEdit(vacc)
  }

  return (
    <>
      {isDataSending &&
        <div className="loader-container">
          <Loader />
        </div>
      }
      {isEditTR &&
        <TRModalTest
          dataEdit={dataEdit}
          setIsEdidTR={setIsEdidTR}
        />
      }
      <h2 className="title">ПЦР-ТЕСТЫ</h2>
      <table className="table-pcrtest">
        <TableHeader 
          columns={columnsUserTest}
          selectedSort={selectedSort}
          setSortBy={setSortBy}
        />
        {Tests.Test.length > 0 ? (
          <TableBody 
            data={sortTestArr}
            showModalScan={showModalScan}
            columns={columnsUserTest}
            editTRData={editTRData}
            deleteValue={deleteValue}
            typeImg={'getPcr'}
            typeScan={'ScanTest'}
          />
        ) : (
          <PCRTest2
            handleDate={handleDate}
            currentSelectedData={currentSelectedData}
            getBase64={getBase64}
            Login={Login}
            dispatch={dispatch}
            updateSaga={updateSaga}
            setShowPCRTest2={setShowPCRTest2}
            Tests={Tests}
          />
        )}
        {showPCRTest2 &&
          <PCRTest2
            handleDate={handleDate}
            currentSelectedData={currentSelectedData}
            getBase64={getBase64}
            Login={Login}
            dispatch={dispatch}
            updateSaga={updateSaga}
            setShowPCRTest2={setShowPCRTest2}
            Tests={Tests}
          /> 
        }
      </table>
      {Tests.Test.length > 0 &&
        <Paginations
          data={Tests}
          updateDataUser={updateDataUser} 
          typeScan={'ScanTest'}
        />
      }
      {(Tests.Test.length > 0 && !showPCRTest2) &&
        <div className="plus-actions" onClick={() => setShowPCRTest2(!showPCRTest2)}>
          <img src={plusPNG} alt="" />
        </div>
      }
    </>
  )
}